<template>
  <div class="ui-orders__wrapper-3">
    <aside class="ui-orders__sidebar">
      <div class="overflow-hidden">
        <CRow class="mb-3">
          <CCol col="auto">
            <h3>Consegne isole</h3>
          </CCol>
          <CCol col="auto">
            <OrdersLegend :statusMap="statusMap" />
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto">
            <CButton
              color="primary"
              @click="onAllDelivered"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >TUTTO CONSEGNATO</CButton
            >
          </CCol>
        </CRow>
      </div>

      <div class="d-flex justify-content-center mt-3" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div v-else>
        <CCard
          class="card-selectable mb-2"
          v-for="ddmi in ddmis"
          :key="ddmi.id"
          :class="cardClasses(ddmi)"
        >
          <CCardBody @click.prevent="selectIsland(ddmi)" class="p-2">
            <CRow class="justify-content-between align-items-center mb-1">
              <CCol>
                <div class="text-truncate">
                  {{ ddmiMarketId(ddmi) }}
                  <strong>{{ ddmiMarketName(ddmi) }}</strong>
                </div>
              </CCol>
              <CCol col="auto">
                <div class="d-flex">
                  <span class="mr-2">
                    entro {{ islandDeliveryDate(ddmi, "dd-MM") }} alle
                    {{ islandDeliveryDate(ddmi, "HH:mm") }}
                  </span>
                  <dropdown-menu
                    :items="actions"
                    :select="(e) => onActionSelect(e, ddmi)"
                    @click.native.stop
                    ><font-awesome-icon icon="ellipsis-h" />
                  </dropdown-menu>
                </div>
              </CCol>
            </CRow>
            <CProgress>
              <CProgressBar
                v-for="progress in ddmiProgressIsland(ddmi)"
                :key="progress.id"
                :color="`orders-${progress.color}`"
                :value="progress.percentage"
                >{{ progress.value }}</CProgressBar
              >
            </CProgress>
            <CRow class="justify-content-between">
              <CCol col="auto">
                {{ islandProdsCount(ddmi) }} Articoli -
                {{ islandOrdersCount(ddmi) }} Ordini
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-2" v-if="!ddmis.length && !loading">
          <CCardBody> Nessun risultato </CCardBody>
        </CCard>
      </div>
    </aside>
    <main class="ui-orders__main">
      <Detail v-if="ddmiActive" :ddmi="ddmiActive" :parentIsLoading="loading" />
    </main>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import Detail from "./Detail";
import OrdersLegend from "../../../share/OrdersLegend.vue";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import {
  ddmiProgressMapIsland,
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
} from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import { mapGetters } from "vuex";
import { buildPredicate } from "../../../../../../helpers/common";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "ViewDdmi",

  mixins: [orderManagementMixin],

  components: {
    Detail,
    OrdersLegend,
  },

  data() {
    const canEdit = this.canView(PERMS.ORDERS_EDIT);
    return {
      PERMS,
      loading: false,
      ddmis: [],
      ddmiActive: null,
      actions: [
        { id: ORDER_ACTION.FILTER, text: "Filtra" },
        {
          id: ORDER_ACTION.DONE,
          text: "Segna come tutto consegnato",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: "Segna come tutto NON consegnato",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: "Apri segnalazione",
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: "Annulla tutto",
          disabled: !canEdit,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    statusMap() {
      return ddmiProgressMapIsland(this.$i18n);
    },
  },

  mounted() {
    this.init();
    EventBus.$on("orders:refresh", this.init);
    EventBus.$on("orders:applyFilters", this.init);
    EventBus.$on("orders:priceUpdate", this.onPriceUpdate);
  },

  beforeDestroy() {
    EventBus.$off("orders:refresh", this.init);
    EventBus.$off("orders:applyFilters", this.init);
    EventBus.$off("orders:priceUpdate", this.onPriceUpdate);
  },

  methods: {
    init() {
      this.getDdmis();
    },

    getFilterQuery() {
      const { keyword } = this.$store.state.orders.filters;

      const map = filtersMap("ddmi.main.filters");

      let predicate = buildPredicate(
        null,
        map,
        this.$store.state.orders.filters
      );

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("ddmi.main.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getDdmis() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const endpoint = filtersMap("ddmi.main.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId]);
      const query = this.getFilterQuery();
      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.ddmis = response.result;
          self.loading = false;

          let selected = null;

          if (self.ddmiActive) {
            selected =
              self.ddmis.find((item) => item.id === self.ddmiActive.id) || null;
          }

          if (selected === null && self.ddmis.length > 0) {
            selected = self.ddmis[0];
          }

          self.selectIsland(selected);
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: $t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectIsland(ddmi) {
      this.ddmiActive = ddmi;
    },

    cardClasses(record) {
      const isSelected = this.ddmiActive && this.ddmiActive.id === record.id;
      const haveError = this.producerDeliveryDateWarning(record);
      return { "card-selected": isSelected, "card-error": haveError };
    },

    onActionSelect(args, record) {
      const ddmis = [record.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (args.item.id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("orders/updateStatusBatch", {
            status: filtersMap("ddmiIsland.statusCode.next"),
            ddmis,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("orders/updateStatusBatch", {
            status: filtersMap("ddmiIsland.statusCode.prev"),
            ddmis,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.FILTER) {
        const param = { id: record.ddmi.id, label: this.islandName(record) };
        this.$store.commit("rounds/updateIslandId", param);
        this.init();
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            {
              type: INCIDENT_ENTITY_TYPE.DDMIS,
              notMarket: true,
              items: [{ ...record }],
            },
          ],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            {
              type: INCIDENT_ENTITY_TYPE.DDMIS,
              notMarket: true,
              items: [{ ...record }],
            },
          ],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    onAllDelivered() {
      const ddmis = this.ddmis.map((item) => item.id);
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("ddmiIsland.statusCode.next"),
          ddmis,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },

    onPriceUpdate() {
      this.init();
    },
  },
};
</script>
