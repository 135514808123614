<template>
  <div class="ui-orders__wrapper-2">
    <ViewDdmp v-if="isView('ddmp')" />
    <ViewDdmi v-if="isView('ddmi')" />
    <ViewIncident v-if="isView('incident')" />
  </div>
</template>

<script>
import ViewDdmp from "./ddmp/View";
import ViewDdmi from "./ddmi/View";
import ViewIncident from "./incident/View";

export default {
  name: "OrdersManageView",
  components: {
    ViewDdmp,
    ViewDdmi,
    ViewIncident,
  },

  methods: {
    isView(view) {
      return this.$store.state.orders.view === view;
    },
  },
};
</script>
