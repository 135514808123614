<template>
  <OrdersCount :total="data.incidents" :open="data.incidents_open"
    >M#{{ getAccount() }}</OrdersCount
  >
</template>

<script>
import get from "lodash/get";
import OrdersCount from "../../../share/OrdersCount.vue";

export default {
  name: "OrdersTableAccount",

  components: {
    OrdersCount,
  },

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getAccount() {
      return get(this.data, "morder");
    },
  },
};
</script>
