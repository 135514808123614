<template>
  <div class="ui-orders__wrapper-3">
    <aside class="ui-orders__sidebar">
      <div class="overflow-hidden">
        <CRow class="mb-3">
          <CCol col="auto">
            <h3>
              {{ $t("role.orders.manage.ddmi.delivery") }} <br />
              {{ $t("role.orders.manage.ddmi.goods") }}
            </h3>
          </CCol>
          <CCol col="auto">
            <OrdersLegend :statusMap="statusMap" />
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto">
            <CButton
              color="primary"
              @click="onAllWithdrawn"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{ $t("role.orders.manage.ddmi.buttonAllDelivered") }}</CButton
            >
          </CCol>
        </CRow>
      </div>

      <div class="d-flex justify-content-center mt-3" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div v-else>
        <CCard
          class="card-selectable mb-2"
          v-for="ddmp in ddmps"
          :key="ddmp.id"
          :class="cardClasses(ddmp)"
        >
          <CCardBody @click.prevent="selectProducer(ddmp)" class="p-2">
            <CRow class="justify-content-between align-items-center mb-1">
              <CCol>
                <div class="text-truncate">
                  {{ ddmpMarketId(ddmp) }}
                  <strong>{{ ddmpMarketName(ddmp) }}</strong>
                </div>
              </CCol>
              <CCol col="auto">
                <div class="d-flex">
                  <span class="mr-2">
                    {{ $t("role.orders.manage.ddmp.detail.delivery.until") }}
                    {{ producerDeliveryDate(ddmp, "dd-MM") }}
                    {{ $t("role.orders.manage.ddmp.detail.delivery.to") }}
                    {{ producerDeliveryDate(ddmp, "HH:mm") }}
                  </span>
                  <dropdown-menu
                    :items="actions"
                    :select="(e) => onActionSelect(e, ddmp)"
                    @click.native.stop
                    ><font-awesome-icon icon="ellipsis-h" />
                  </dropdown-menu>
                </div>
              </CCol>
            </CRow>
            <CProgress>
              <CProgressBar
                v-for="progress in ddmpProgress(ddmp)"
                :key="progress.id"
                :color="`orders-${progress.color}`"
                :value="progress.percentage"
                >{{ progress.value }}</CProgressBar
              >
            </CProgress>
            <CRow class="justify-content-between">
              <CCol col="auto">
                {{ producerProdsCount(ddmp) }} Articoli -
                {{ producerOrdersCount(ddmp) }} Ordini
                <span
                  v-if="producerOrdersNotWeighed(ddmp) > 0"
                  class="text-warning ml-2"
                >
                  (<font-awesome-icon icon="balance-scale" />
                  {{ producerOrdersNotWeighed(ddmp) }} non pesati)
                </span>
              </CCol>
              <CCol col="auto"
                ><strong
                  >{{ producerTotPrice(ddmp)
                  }}<sup>{{ currencySymbol }}</sup></strong
                ></CCol
              >
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-2" v-if="!ddmps.length && !loading">
          <CCardBody> Nessun risultato </CCardBody>
        </CCard>
      </div>
    </aside>
    <main class="ui-orders__main">
      <Detail v-if="ddmpActive" :ddmp="ddmpActive" :parentIsLoading="loading" />
    </main>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import Detail from "./Detail";
import OrdersLegend from "../../../share/OrdersLegend.vue";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
  producersProgressMap,
} from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import { mapGetters, mapState } from "vuex";
import { buildPredicate } from "../../../../../../helpers/common";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ViewDdmp",

  mixins: [orderManagementMixin],

  components: {
    Detail,
    OrdersLegend,
  },

  data() {
    const canEdit = this.canView(PERMS.ORDERS_EDIT);
    return {
      PERMS,
      loading: false,
      ddmps: [],
      ddmpActive: null,
      actions: [
        {
          id: ORDER_ACTION.FILTER,
          text: this.$t("role.orders.manage.ddmp.orderActions.filter"),
        },
        {
          id: ORDER_ACTION.DONE,
          text: this.$t("role.orders.manage.ddmp.orderActions.allDelivered"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: this.$t("role.orders.manage.ddmp.orderActions.allNotDelivered"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: this.$t("role.orders.manage.ddmp.orderActions.openIncident"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: this.$t("role.orders.manage.ddmp.orderActions.cancel"),
          disabled: !canEdit,
        },
      ],
    };
  },

  watch: {
    round() {
      this.init();
    },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    ...mapState("rounds", ["round"]),

    statusMap() {
      return producersProgressMap(this.$i18n);
    },
  },

  mounted() {
    this.init();
    EventBus.$on("orders:refresh", this.init);
    EventBus.$on("orders:applyFilters", this.init);
    EventBus.$on("orders:priceUpdate", this.onPriceUpdate);
  },

  beforeDestroy() {
    EventBus.$off("orders:refresh", this.init);
    EventBus.$off("orders:applyFilters", this.init);
    EventBus.$off("orders:priceUpdate", this.onPriceUpdate);
  },

  methods: {
    init() {
      this.getDdmps();
    },

    getFilterQuery() {
      const { keyword } = this.$store.state.orders.filters;

      const map = filtersMap("ddmp.main.filters");

      let predicate = buildPredicate(
        null,
        map,
        this.$store.state.orders.filters
      );

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("ddmp.main.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getDdmps() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const endpoint = filtersMap("ddmp.main.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId]);
      const query = this.getFilterQuery();
      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.ddmps = response.result;
          self.loading = false;

          let selected = null;

          if (self.ddmpActive) {
            selected =
              self.ddmps.find((item) => item.id === self.ddmpActive.id) || null;
          }

          if (selected === null && self.ddmps.length > 0) {
            selected = self.ddmps[0];
          }

          self.selectProducer(selected);
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: $t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectProducer(producer) {
      this.ddmpActive = producer;
    },

    cardClasses(ddmp) {
      const isSelected = this.ddmpActive && this.ddmpActive.id === ddmp.id;
      const haveError = this.producerDeliveryDateWarning(ddmp);
      const isDirect = ddmp.ddmp.delivery_type == 3;
      return {
        "card-selected": isSelected,
        "card-error": haveError,
        "card-direct": isDirect,
      };
    },

    onActionSelect(args, ddmp) {
      const ddmps = [ddmp.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (args.item.id === ORDER_ACTION.FILTER) {
        const entity = { id: ddmp.id, label: this.producerName(ddmp) };
        this.$store.commit("rounds/updateProducerId", entity);
        this.init();
      }

      if (args.item.id === ORDER_ACTION.DONE) {
        let nextStatus = filtersMap("ddmp.statusCode.next");
        if (ddmp.ddmp.delivery_type == 3) {
          nextStatus = filtersMap("ddmiIsland.statusCode.next");
        }
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: nextStatus,
            ddmps,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.prev"),
            ddmps,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.DDMPS, items: [ddmp] }],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.DDMPS, items: [ddmp] }],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    onAllWithdrawn() {
      console.log(this.ddmps);
      // recupera delivery type delle consegne
      const delivery_type = this.ddmps.map((item) => item.ddmp.delivery_type);
      // console.log(delivery_type)
      let next_status = filtersMap("ddmp.statusCode.next");
      if (delivery_type.includes(3)) {
        next_status = filtersMap("ddmiIsland.statusCode.next");
      }
      const ddmps = this.ddmps.map((item) => item.id);
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: next_status,
          ddmps,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },

    onPriceUpdate(payload) {
      const { ddmp } = payload.response;
      const idx = this.ddmps.findIndex((item) => item.id === ddmp.id);
      if (idx !== undefined) {
        this.ddmps[idx] = {
          ...this.ddmps[idx],
          tot_price: ddmp.price,
          tot_price_p: ddmp.credit_producer,
          need_w: ddmp.need_w,
        };
        this.ddmps = cloneDeep(this.ddmps);
      }
    },
  },
};
</script>
